import React from "react";
import {TbCurrencyDram} from "react-icons/tb";
import {BiRightArrowAlt} from "react-icons/bi";
import {Link} from "react-router-dom";

export default function ReadModeTableData(props) {
	return (
		<div className="col-12 p-0 custom_table">
			<div className="tabel-div tabel-hover d-flex align-items-center py-0">
				<div className="extra-narrow">{props?.data?.identifier}</div>
				<div className="wide">{props?.data?.type.title_am}</div>
				<div className="extra-narrow ">{props?.data?.category?.code}</div>
				<div className="wide text-start">{props?.data?.category?.title_am}</div>
				<div className="narrow">
					{props?.data?.count} {props?.data?.unit?.title_am}
				</div>
				<div className="d-grid narrow">
					<span>
						{parseFloat(props?.data?.salary)} {props?.data?.project?.currency.title_am}
					</span>
					<span>
						{(
							parseFloat(props?.data?.salary) * parseFloat(props?.data?.project?.currency_value)
						).toFixed(2)}
						<TbCurrencyDram />
					</span>
				</div>
				<div className="d-grid narrow">
					<span>
						{props?.data?.count * parseFloat(props?.data?.salary)}{" "}
						{props?.data?.project?.currency.title_am}
					</span>
					<span>
						{(
							props?.data?.count *
							parseFloat(props?.data?.salary) *
							parseFloat(props?.data?.project?.currency_value)
						).toFixed(2)}

						<TbCurrencyDram />
					</span>
				</div>
				<div className="narrow d-flex justify-content-center align-items-center py-3">
					<Link
						to={`/budget/mounthly/${props?.data?.id}`}
						state={{data: props?.data}}
						className="btn btn-primary d-flex justify-content-center align-items-center text-center rounded-4 p-1"
					>
						<BiRightArrowAlt />
					</Link>
				</div>
			</div>
		</div>
	);
}
