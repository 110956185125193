import React from "react";
import {Routes, Route} from "react-router-dom";
import ErrorPage from "../ui/ErrorPage";
import Currencies from "./Currencies";
import InfromersBase from "./InfromersBase";
import PermissionsDashboard from "../users/PermissionsDashboard";
import Taxes from "./Taxes";
import Messages from "./Messages";
import Expances from "./Expances";
import OrganizationDetails from "./OrganizationDetails";
import CreateUser from "../users/CreateUser";
import Infromers from "./Informers";
import AddAcc from "./AddAcc";
import DocumentsTemplates from "./DocumentsTemplates";
import RatingsInformerPage from "./RatingsInformerPage";
import {
	base,
	currency,
	acc,
	unit,
	bank,
	languages_url,
	donors,
	organization_types,
	service_type,
	payment_types,
	workplace_url,
	probation_period_url,
	position_and_service,
	file_tags_url,
	locations_url,
	statuses_url,
	spheres_url,
	organization_details,
	educations,
	expance_type,
	ratings_url, // change with actual url
} from "../../api";
import {informersDescription} from "../../json_content/StaticTexts";
import {sections} from "../../json_content/Informer_sections";

export default function InformersRoutes() {
	return (
		<div className="row">
			<Routes>
				<Route index path="/" element={<InfromersBase />} />
				<Route
					exact
					path="oraganization_details"
					element={
						<OrganizationDetails
							url={base + organization_details}
							description={informersDescription}
							perm={sections["oraganization_details"].perm}
						/>
					}
				/>
				<Route
					exact
					path="platform_users"
					element={<CreateUser perm={sections["platform_users"].perm} />}
				/>
				<Route
					exact
					path="authorizations"
					element={<PermissionsDashboard perm={sections["authorizations"].perm} />}
				/>
				<Route
					exact
					path="donors"
					element={
						<Infromers
							url={base + donors}
							description={informersDescription}
							perm={sections["donors"].perm}
						/>
					}
				/>
				<Route
					exact
					path="organization_types"
					element={
						<Infromers
							url={base + organization_types}
							description={informersDescription}
							perm={sections["organization_types"].perm}
						/>
					}
				/>
				<Route
					exact
					path="service_types"
					element={
						<Infromers
							url={base + service_type}
							description={informersDescription}
							perm={sections["service_types"].perm}
						/>
					}
				/>
				<Route
					exact
					path="statuses"
					element={
						<Infromers
							url={base + statuses_url}
							description={informersDescription}
							perm={sections["statuses"].perm}
						/>
					}
				/>
				<Route
					exact
					path="spheres"
					element={
						<Infromers
							url={base + spheres_url}
							description={informersDescription}
							perm={sections["spheres"].perm}
						/>
					}
				/>
				<Route
					exact
					path="position_and_service"
					element={
						<Infromers
							url={base + position_and_service}
							description={informersDescription}
							perm={sections["position_and_service"].perm}
						/>
					}
				/>
				<Route
					exact
					path="banks"
					element={
						<Infromers
							url={base + bank}
							description={informersDescription}
							perm={sections["banks"].perm}
						/>
					}
				/>
				<Route
					exact
					path="acc"
					element={
						<AddAcc
							url={base + acc}
							description={informersDescription}
							perm={sections["acc"].perm}
						/>
					}
				/>
				<Route
					exact
					path="currencies"
					element={<Currencies url={base + currency} perm={sections["currencies"].perm} />}
				/>
				<Route
					exact
					path="units"
					element={
						<Infromers
							url={base + unit}
							description={informersDescription}
							perm={sections["units"].perm}
						/>
					}
				/>
				<Route
					exact
					path="languages"
					element={
						<Infromers
							url={base + languages_url}
							description={informersDescription}
							perm={sections["languages"].perm}
						/>
					}
				/>
				<Route
					exact
					path="educations"
					element={
						<Infromers
							url={base + educations}
							description={informersDescription}
							perm={sections["educations"].perm}
						/>
					}
				/>
				<Route exact path="taxes" element={<Taxes perm={sections["taxes"].perm} />} />
				<Route
					exact
					path="expance_category"
					element={<Expances perm={sections["expance_category"].perm} />}
				/>
				<Route
					exact
					path="expance_types"
					element={<Infromers url={base + expance_type} perm={sections["expance_types"].perm} />}
				/>
				<Route
					exact
					path="workplace"
					element={<Infromers url={base + workplace_url} perm={sections["currencies"].perm} />}
				/>
				<Route
					exact
					path="ratings"
					element={<RatingsInformerPage url={base + ratings_url} perm={sections["ratings"].perm} />}
				/>
				<Route
					exact
					path="probation_period"
					element={
						<Infromers url={base + probation_period_url} perm={sections["currencies"].perm} />
					}
				/>
				<Route
					exact
					path="locations"
					element={
						<Infromers
							url={base + locations_url}
							description={informersDescription}
							perm={sections["locations"].perm}
						/>
					}
				/>
				<Route
					exact
					path="tags"
					element={
						<Infromers
							url={base + file_tags_url}
							description={informersDescription}
							perm={sections["tags"].perm}
						/>
					}
				/>
				<Route
					exact
					path="inbox"
					element={
						<Infromers
							url={base + file_tags_url}
							description={informersDescription}
							perm={sections["inbox"].perm}
						/>
					}
				/>
				<Route
					exact
					path="document_templates"
					element={
						<DocumentsTemplates
							url={base + file_tags_url}
							description={informersDescription}
							perm={sections["inbox"].perm}
						/>
					}
				/>
				{/* <Route exact path="payment_types" element={<Infromers url={base + payment_types} description={informersDescription} perm={sections['payment_types'].perm} />} /> */}
				{/* <Route exact path="messages" element={<Messages perm={sections['messages'].perm} />} /> */}
				<Route exact path="/*" element={<ErrorPage />} />
			</Routes>
		</div>
	);
}
