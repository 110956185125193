import React, {useState, useEffect, useRef} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FilterMatchMode, FilterOperator} from "primereact/api";
import {InputText} from "primereact/inputtext";
import {AutoComplete} from "primereact/autocomplete";
import {TabView, TabPanel} from "primereact/tabview";
import {Button} from "primereact/button";
import {Toast} from "primereact/toast";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {Skeleton} from "primereact/skeleton";

import {
	base,
	partners,
	coworker_url,
	partnersLegal,
	partnersIndividual,
	partnersCompany,
} from "../../api";
import axios from "axios";

import walletInfo from "../../assets/images/icons/unicons/community.png";
import computer from "../../assets/images/icons/unicons/briefcase-round.png";
import chartSuccess from "../../assets/images/icons/unicons/bookmark.png";
import briefcase from "../../assets/images/icons/unicons/cube-secondary.png";
import CoWorkerWidget from "./CoWorkerWidget";
import {Dialog} from "primereact/dialog";
import AddCoWorkerForm from "./AddCoWorkerForm";
import DashboardComponents from "./components/DashboardComponents";
import EditCoWorker from "./EditCoWorker";

export default function CoWorkersDashboard() {
	const dt = useRef(null);
	const toast = useRef(null);
	const headers = {"Content-Type": "application/json"};
	const credentials = "same-origin";

	const [userInfo, setUserInfo] = useState(null);
	const [coWorkerDialog, setCoWorkerDialog] = useState();
	const [editDialogHeader, setEditDialogHeader] = useState();
	const [contractType, setContractType] = useState({
		physical: "",
		individual: "",
		legal: "",
	});

	const [currentState, setCurrentState] = useState();
	const [partnerInfo, setPartnerInfo] = useState();

	const [selectedPartner, setSelectetPartner] = useState();
	const [filteredPartner, setFilteredPartner] = useState();
	const [editDialog, setEditDialog] = useState(false);

	useEffect(() => {
		loadContent();
	}, []);

	const loadContent = () => {
		axios.all([axios.get(base + partners), axios.get(base + coworker_url)]).then(
			axios.spread((...response) => {
				setPartnerInfo(response[0].data);
				setContractType({
					...contractType,
					physical: response[1].data?.filter(i => i.contract_type === 1),
					individual: response[1].data?.filter(i => i.contract_type === 2),
					legal: response[1].data?.filter(i => i.contract_type === 3),
				});
			})
		);
	};

	function remove() {
		axios
			.delete(base + coworker_url + localStorage.getItem("item") + `/`, {headers: headers})
			.then(res => {
				loadContent();
				localStorage.removeItem("item");
			})
			.catch(error => {
				loadContent();
			});
	}

	const accept = () => {
		toast.current.show({
			severity: "success",
			summary: "Confirmed",
			detail: "You have accepted",
			life: 3000,
		});
		remove();
	};
	const reject = () => {
		toast.current.show({
			severity: "warn",
			summary: "Rejected",
			detail: "You have rejected",
			life: 3000,
		});
	};

	const confirmDelete = e => {
		confirmDialog({
			message: "Do you want to delete this record?",
			header: "Delete Confirmation",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			accept,
			reject,
		});
	};

	const [globalFilterValue, setGlobalFilterValue] = useState("");
	const [filters, setFilters] = useState({
		global: {value: null, matchMode: FilterMatchMode.CONTAINS},
		full_name: {
			operator: FilterOperator.AND,
			constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}],
		},
		"country.name": {
			operator: FilterOperator.AND,
			constraints: [{value: null, matchMode: FilterMatchMode.STARTS_WITH}],
		},
		phone: {value: null, matchMode: FilterMatchMode.IN},
		email: {
			operator: FilterOperator.OR,
			constraints: [{value: null, matchMode: FilterMatchMode.EQUALS}],
		},
	});

	const onGlobalFilterChange = event => {
		const value = event.target.value;
		let _filters = {...filters};
		_filters["global"].value = value;
		setFilters(_filters);
	};

	const renderHeader = () => {
		const value = filters["global"] ? filters["global"].value : "";
		return (
			<div className="d-flex justify-content-between">
				<span className="p-input-icon-left">
					<i className="pi pi-search" />
					<InputText
						type="search"
						value={value || ""}
						onChange={e => onGlobalFilterChange(e)}
						placeholder="Global Search"
					/>
				</span>
				<div className="flex align-items-center justify-content-end gap-2">
					<Button
						label="export selected as csv file"
						icon="pi pi-file"
						className="p-button-help"
						outlined
						onClick={exportCSV}
					/>
					<Button
						label="export selected as excel file"
						icon="pi pi-file-excel"
						severity="success"
						outlined
						onClick={exportExcel}
						data-pr-tooltip="XLS"
					/>
					<Button
						label="export selected as pdf file"
						icon="pi pi-file-pdf"
						severity="warning"
						outlined
						onClick={exportPdf}
						data-pr-tooltip="PDF"
					/>
				</div>
			</div>
		);
	};

	const projectIDtemplate = rowData => {
		return (
			<div className="row p-0">
				<CoWorkerWidget
					data={rowData}
					editDialog={editDialog}
					setEditDialog={setEditDialog}
					confirmDelete={confirmDelete}
					setUserInfo={setUserInfo}
				/>
			</div>
		);
	};
	const search = event => {
		let query = event.query;
		let _filteredPartner = partnerInfo?.filter(
			item => item?.full_name_am?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
		);
		setFilteredPartner(_filteredPartner);
	};
	const exportCSV = currentState => {
		dt.current.exportCSV({currentState});
	};
	const exportPdf = () => {
		import("jspdf").then(currentState => {
			import("jspdf-autotable").then(() => {
				const doc = new currentState.default(0, 0);
				doc.autoTable(currentState);
				doc.save("currentState.pdf");
			});
		});
	};

	const exportExcel = () => {
		import("xlsx").then(xlsx => {
			const worksheet = xlsx.utils.json_to_sheet(currentState);
			const workbook = {Sheets: {data: worksheet}, SheetNames: ["DataSheet"]};
			const excelBuffer = xlsx.write(workbook, {
				bookType: "xlsx",
				type: "array",
			});
			saveAsExcelFile(excelBuffer, "DataSheet");
		});
	};
	const saveAsExcelFile = (buffer, fileName) => {
		import("file-saver").then(module => {
			if (module && module.default) {
				let EXCEL_TYPE =
					"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
				let EXCEL_EXTENSION = ".xlsx";
				const data = new Blob([buffer], {
					type: EXCEL_TYPE,
				});

				module.default.saveAs(data, fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION);
			}
		});
	};
	const header = renderHeader();
	return (
		<>
			<div className="row mb-3 match-height p-0">
				<DashboardComponents
					image={walletInfo}
					title={"Ընդհանուր"}
					info={
						contractType?.physical.length +
						contractType?.individual.length +
						contractType?.legal.length
					}
				/>
				<DashboardComponents
					image={computer}
					title={"Հաստիքային"}
					info={contractType?.physical.length}
				/>
				<DashboardComponents
					image={briefcase}
					title={"Քաղ իրավ"}
					info={contractType?.individual.length}
				/>
				<DashboardComponents
					image={chartSuccess}
					title={"Մատակարար"}
					info={contractType?.legal.length}
				/>
			</div>

			<div className="col-12 p-0 my-4">
				<div className="card b-radius b-shadow p-3">
					<p className="d-flex align-items-center text-muted mb-0 text-uppercase">
						{" "}
						Ավելացնել նախագծի օգտատեր
					</p>
					<hr />
					<div className="card flex justify-content-center p-3">
						{partnerInfo ? (
							<AutoComplete
								dropdown
								placeholder="Մուտքագրեք օգտատեր"
								field={"full_name_am"}
								completeMethod={search}
								suggestions={filteredPartner}
								value={selectedPartner}
								onChange={e => setSelectetPartner(e.value)}
								itemTemplate={item => (
									<div className="d-flex align-items-top" onClick={e => setCoWorkerDialog(true)}>
										<div className="d-grid">
											<span className="text-primary">{item?.full_name_am} </span>
											<span className="text-secondary small">
												{item?.address_en ?? "not provided"}
											</span>
											<span className="text-secondary small">
												{item?.passport ?? "not provided"}
											</span>
										</div>
									</div>
								)}
							/>
						) : (
							<Skeleton width="100%" height="45px"></Skeleton>
						)}
					</div>
				</div>
			</div>

			<div className="col-lg-12 mb-4">
				{partnerInfo ? (
					<>
						<TabView className="card b-radius b-shadow p-4">
							<TabPanel
								header="Հիմնական աշխատակիցներ"
								rightIcon="pi pi-user ml-2"
								headerClassName="p-0"
								className="p-0"
							>
								<DataTable
									className="row p-0"
									ref={dt}
									value={contractType.physical}
									selectionMode="checkbox"
									selection={currentState}
									onSelectionChange={e => setCurrentState(e.value)}
									paginator
									rows={20}
									rowsPerPageOptions={[20, 30, 50, 100]}
									dataKey="id"
									filters={filters}
									onFilter={e => setFilters(e?.filters)}
									globalFilterFields={["full_name"]}
									emptyMessage="No customers found."
								>
									<Column
										selectionMode="multiple"
										exportable={true}
										headerStyle={{width: "3rem"}}
									></Column>
									<Column body={projectIDtemplate} className="p-0 ps-4"></Column>
								</DataTable>
							</TabPanel>
							<TabPanel
								header="Քաղ. իրավ."
								rightIcon="pi pi-user ml-2"
								headerClassName="p-0"
								className="p-0"
							>
								<DataTable
									ref={dt}
									value={contractType.individual}
									selection={currentState}
									onSelectionChange={e => setCurrentState(e.value)}
									paginator
									rows={20}
									rowsPerPageOptions={[20, 30, 50, 100]}
									dataKey="id"
									filters={filters}
									onFilter={e => setFilters(e?.filters)}
									globalFilterFields={["full_name"]}
									emptyMessage="No customers found."
								>
									<Column body={projectIDtemplate} className="border-0 px-3 py-0"></Column>
								</DataTable>
							</TabPanel>
							<TabPanel
								header="Մատակարարներ և ծառայություն մատուցողներ"
								rightIcon="pi pi-user ml-2"
								headerClassName="p-0"
								className="p-0"
							>
								<DataTable
									ref={dt}
									value={contractType.legal}
									selection={currentState}
									onSelectionChange={e => setCurrentState(e.value)}
									paginator
									rows={20}
									rowsPerPageOptions={[20, 30, 50, 100]}
									dataKey="id"
									filters={filters}
									onFilter={e => setFilters(e?.filters)}
									globalFilterFields={["full_name"]}
									emptyMessage="No customers found."
								>
									<Column body={projectIDtemplate} className="border-0 px-3 py-0"></Column>
								</DataTable>
							</TabPanel>
						</TabView>
					</>
				) : (
					<div className="col-12 d-flex justify-content-center align-items-center my-5 py-5">
						<span class="loader"></span>
					</div>
				)}
				<Toast ref={toast} />
				<ConfirmDialog />
				<Dialog
					className="col-lg-8 col-12"
					header={
						selectedPartner?.full_name_am
							? selectedPartner?.full_name_am
							: selectedPartner?.company_am + " (" + selectedPartner?.full_name_en
							? selectedPartner?.full_name_en
							: selectedPartner?.company_en + ") " + selectedPartner.id
					}
					visible={coWorkerDialog}
					onHide={() => setCoWorkerDialog(false)}
				>
					<AddCoWorkerForm
						data={selectedPartner}
						setCoWorkerDialog={setCoWorkerDialog}
						loadContent={loadContent}
						toast={toast}
						id={currentState?.id}
					/>
				</Dialog>
				<Dialog
					className="col-lg-8 col-12"
					header={
						editDialogHeader?.full_name_am +
						" (" +
						((editDialogHeader?.legal == "Physical" && "Հաստիքային աշխատակից") ||
							(editDialogHeader?.legal == "Individual" && "Քաղ. իրավ.") ||
							(editDialogHeader?.legal == "Legal" && "Մատակարար")) +
						")"
					}
					visible={editDialog}
					onHide={() => setEditDialog(false)}
				>
					<EditCoWorker
						userInfo={userInfo}
						setCoWorkerDialog={setCoWorkerDialog}
						loadContent={loadContent}
						toast={toast}
						setEditDialog={setEditDialog}
					/>
				</Dialog>
			</div>
		</>
	);
}
