import React, {useState, useEffect} from "react";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Checkbox} from "primereact/checkbox";

export default function BudgetMounthlyAddForm(props) {
	const [value, setValue] = useState({});
	const [checked, setChecked] = useState(false);
	const headers = {"Content-Type": "application/json"};
	const credentials = "same-origin";
	let isFilled = Object.keys(value).length >= 6;
	for (const key in value) {
		if (!value[key]) {
			isFilled = false;
		}
	}
	console.log(props);
	return (
		<div className="col-12 p-3">
			<div className="row">
				<div className="col-lg-6">
					<InputText
						type="date"
						onChange={e => setValue({...value, identifier: e.target.value})}
						className="py-3 w-100 m-2"
						placeholder="No:"
					/>
				</div>
				<div className="col-lg-6">
					<InputText
						onChange={e => setValue({...value, count: e.target.value})}
						className="py-3 w-100 m-2"
						placeholder="Քանակ"
					/>
				</div>

				<div className="col-lg-12 text-center mt-4">
					<Button
						icon={"pi pi-save"}
						rounded
						label="պահպանել"
						severity={isFilled ? "success" : "secondary"}
						// onClick={submitData}
						disabled={!isFilled}
					/>
				</div>
			</div>
		</div>
	);
}
