import React, {useEffect, useState, useRef} from "react";
import axios from "axios";
import {Button} from "primereact/button";
import {
	base,
	expance,
	unit,
	budget_by_project,
	expance_type,
	budget_by_project_create,
} from "../../api";
import {Dialog} from "primereact/dialog";
import {Toast} from "primereact/toast";
import EditableRow from "./components/EditableRow";

import {Calendar} from "primereact/calendar";

export default function BudgetProjects(props) {
	const toast = useRef(null);
	const [isEditing, setIsEditing] = useState(true);
	const [dialogVisibility, setDialogVisibility] = useState(false);
	const [optionExpances, setOptionExpances] = useState([]);
	const [optionExpanceType, setOptionExpanceType] = useState([]);
	const [optionUnit, setOptionUnit] = useState([]);
	const [budgetByProject, setBudgetByProject] = useState();
	const [filterDialog, setFilterDialog] = useState(false);

	const dateStringToObject = dateStr => {
		return new Date(dateStr);
	};

	const dateObjectToString = dateObj => {
		if (!dateObj) return "";
		const year = dateObj.getFullYear();
		const month = String(dateObj.getMonth() + 1).padStart(2, "0");
		const day = String(dateObj.getDate()).padStart(2, "0");
		return `${year}-${month}-${day}`;
	};

	const minimumDate = props?.project?.start_date
		? dateStringToObject(props.project.start_date)
		: null;
	const maximumDate = props?.project?.end_date ? dateStringToObject(props.project.end_date) : null;

	const [selectedDayRange, setSelectedDayRange] = useState({
		from: null,
		to: null,
	});

	useEffect(() => {
		const startDateStr = props?.project?.start_date;
		const endDateStr = props?.project?.end_date;
		setSelectedDayRange({
			from: startDateStr ? dateStringToObject(startDateStr) : null,
			to: endDateStr ? dateStringToObject(endDateStr) : null,
		});
	}, [props?.project?.start_date, props?.project?.end_date]);

	useEffect(() => {
		loadContent();
	}, []);

	const onFilter = () => {
		loadContent(
			`?from_date=${dateObjectToString(selectedDayRange.from)}&to_date=${dateObjectToString(
				selectedDayRange.to
			)}`
		);
		setFilterDialog(false);
	};

	const loadContent = (params = null) => {
		axios
			.get(`${base}${budget_by_project}${props?.projectID}/${params ? params : ""}`)
			.then(res => {
				setBudgetByProject(res.data.filter(budget => budget.count > 0));
			});
		axios
			.all([axios.get(base + expance), axios.get(base + unit), axios.get(base + expance_type)])
			.then(
				axios.spread((...response) => {
					setOptionExpances(response[0].data);
					setOptionUnit(response[1].data);
					setOptionExpanceType(response[2].data);
				})
			);
	};

	const headerElement = (
		<div className="d-grid align-items-center">
			<span className="text-primary">{props?.project?.number}</span>
			<span className="small fw-normal">{props?.project?.name}</span>
			<span className="small fw-normal text-truncate">{props?.project?.full_name_am}</span>
		</div>
	);

	return (
		<>
			<div className="w-100 rounded-4 shadow p-3 card">
				<div className="text-start my-3 fs-4 text-uppercase fw-bold px-3 border-bottom pb-3 d-flex align-items-center">
					<i className="pi pi-info-circle me-2 fs-4 fw-bold"></i>
					Բյուջե
				</div>
				<div className="d-flex justify-content-between px-3 mb-3">
					<div className="text-start my-3">
						<Button label="Ավելացնել ծախս" rounded onClick={() => setDialogVisibility(true)} />
					</div>

					<div className="col-lg-4 col-12 text-end">
						{/* {(selectedDayRange?.from, selectedDayRange?.to)} */}
					</div>

					<div className="col-lg-4 col-12 text-end">
						<Button
							label="Filter budget"
							rounded
							severity="primary"
							icon="pi pi-filter"
							onClick={() => setFilterDialog(true)}
						/>
						<Dialog
							header={null}
							className="col-lg-6 col-md-5 col-6 mx-auto"
							visible={filterDialog}
							onHide={() => {
								if (!filterDialog) return;
								setFilterDialog(false);
							}}
						>
							<div className="row">
								<Calendar
									value={[selectedDayRange.from, selectedDayRange.to]}
									onChange={e => setSelectedDayRange({from: e.value[0], to: e.value[1]})}
									selectionMode="range"
									readOnlyInput
									inline
									minDate={minimumDate}
									maxDate={maximumDate}
									numberOfMonths={2}
								/>

								<div className="col-12 mt-5 mb-3 text-center">
									<Button label={"Filter"} rounded onClick={onFilter} />
								</div>
							</div>
						</Dialog>
					</div>
				</div>
				{budgetByProject?.length > 0 ? (
					<>
						<div className="col-12 p-0">
							<div className="tabel-div d-flex align-items-center p-0">
								<div className="extra-narrow">No:</div>
								<div className="wide">Հոդված</div>
								<div className="extra-narrow">Կոդ</div>
								<div className="wide">Ծախսերի կատեգորիա</div>
								<div className="narrow">Քանակ</div>
								<div className="narrow">Գին</div>
								<div className="narrow">Ընդհանուր գումար </div>
								<div className="narrow">Մանրամասներ </div>
							</div>
							{budgetByProject?.map((item, i) => (
								<EditableRow
									typeOptions={optionExpances}
									optionUnit={optionUnit}
									{...item}
									minmax_range={selectedDayRange}
									project={props?.project}
								/>
							))}
						</div>
					</>
				) : (
					<div className="col-12">
						<div className="card w-100 border-0 text-center">Դեռ նշումներ չկան</div>
					</div>
				)}
				<Dialog
					visible={dialogVisibility}
					modal
					header={headerElement}
					style={{width: "50rem"}}
					onHide={() => {
						if (!dialogVisibility) return;
						setDialogVisibility(false);
					}}
				>
					<EditableRow
						isEditing={isEditing}
						typeOptions={optionExpances}
						optionUnit={optionUnit}
						toast={toast}
						optionExpanceType={optionExpanceType}
						project={props?.project}
						loadContent={loadContent}
						setContentLoadTrigger={props?.setContentLoadTrigger}
						setDialogVisibility={setDialogVisibility}
						url={base + budget_by_project_create}
					/>
				</Dialog>
				<Toast ref={toast} />
			</div>
		</>
	);
}
