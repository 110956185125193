import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import {base, projects_url} from "../../api";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {FiArrowLeft} from "react-icons/fi";
import {AutoComplete} from "primereact/autocomplete";
import {Skeleton} from "primereact/skeleton";

export default function VolunteersForm(props) {
	const [value, setValue] = useState();

	const [partnerInfo, setPartnerInfo] = useState();

	const [selectedProject, setSelectetProject] = useState();
	const [filteredProject, setFilteredProject] = useState();
	const [ongoingProjects, setOngoingProjects] = useState([]);
	const [complatedProjects, setComplatedProjects] = useState([]);
	const [allProjects, setAllProjects] = useState([]);

	useEffect(() => {
		loadData();
	}, []);

	function loadData() {
		axios.get(base + projects_url).then(res => {
			setComplatedProjects(
				res.data.sort((a, b) => b.id - a.id).filter(item => item.completed == false)
			);
			setOngoingProjects(
				res.data.sort((a, b) => b.id - a.id).filter(item => item.completed == true)
			);
			setAllProjects(res.data.sort((a, b) => b.id - a.id));
		});
	}

	const search = event => {
		let query = event.query;
		let _filteredPartner = allProjects?.filter(
			item => item?.full_name_am?.toLowerCase().indexOf(query?.toLowerCase()) !== -1
		);
		setFilteredProject(_filteredPartner);
	};

	return (
		<div className="col-12 p-0">
			<div className="card border-0 w-100 b-radius b-shadow p-3 mb-4">
				<div className="row p-3">
					<div className="fs-5 my-3">Կամավորի տվյալներ</div>
					<hr />
					<div className="flex flex-column col-lg-8 col-md-6 col-12 ">
						<label htmlFor="username">Ծրագիր</label>
						{allProjects ? (
							<AutoComplete
								dropdown
								className="py-3"
								placeholder="Մուտքագրեք օգտատեր"
								field={"full_name_am"}
								completeMethod={search}
								suggestions={filteredProject}
								value={selectedProject}
								onChange={e => setSelectetProject(e.value)}
								itemTemplate={item => (
									<div className="d-flex align-items-top">
										<div className="d-grid">
											<span className="text-primary">{item?.full_name_am.substring(0, 75)} </span>
											<span className="text-secondary small">{item?.name ?? "not provided"}</span>
											<span className="text-secondary small">
												{item?.name_en ?? "not provided"}
											</span>
										</div>
									</div>
								)}
							/>
						) : (
							<Skeleton width="100%" height="55px"></Skeleton>
						)}
					</div>
					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Անուն ազգանուն</label>
						<InputText id="username" className="py-3" />
					</div>
					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Հեռախոսահամար</label>
						<InputText id="username" className="py-3" />
					</div>
					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Էլ. հասցե</label>
						<InputText id="username" className="py-3" />
					</div>

					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Ծննդյան օր / տարեթիվ</label>
						<input
							type="date"
							id="accept_date"
							className="form-control py-3"
							dateFormat="dd/mm/yy"
						/>
					</div>
					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Ծննդյան վկայական</label>
						<InputText id="username" className="py-3" />
					</div>
					<div className="flex flex-column col-lg-8 gap-2 mt-2">
						<label htmlFor="username">Հասցե</label>
						<InputText id="username" className="py-3" />
					</div>
					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Սեռ</label>
						<div className="d-flex align-items-center py-3">
							<div class="form-check me-3">
								<input
									class="form-check-input"
									type="radio"
									name="flexRadioDefault"
									id="flexRadioDefault1"
								/>
								<label class="form-check-label" for="flexRadioDefault1">
									Արական
								</label>
							</div>
							<div class="form-check ms-3">
								<input
									class="form-check-input"
									type="radio"
									name="flexRadioDefault"
									id="flexRadioDefault2"
								/>
								<label class="form-check-label" for="flexRadioDefault2">
									Իգական
								</label>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="card border-0 w-100 b-radius b-shadow p-3 mt-4">
				<div className="row p-3">
					<div className="fs-5 my-3">Լիազոր անձ</div>
					<hr />
					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Ծնողի կամ լիազորված անձի անուն ազգանուն</label>
						<InputText id="username" className="py-3" />
					</div>
					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Անձնագիր կամ նույնականացման քարտ</label>
						<InputText id="username" className="py-3" />
					</div>
					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Հեռախոսահամար</label>
						<InputText id="username" className="py-3" />
					</div>
					<div className="flex flex-column col-lg-4 col-md-6 col-12 gap-2">
						<label htmlFor="username">Էլ. հասցե</label>
						<InputText id="username" className="py-3" />
					</div>
					<div className="flex flex-column col-8 gap-2">
						<label htmlFor="username">Հասցե</label>
						<InputText id="username" className="py-3" />
					</div>
				</div>
			</div>
			<div className="card border-0 w-100 b-radius b-shadow p-3 mt-4">
				<div className="d-flex aling-items-center justify-content-center">
					<Link
						to={"/volunteers"}
						className="btn btn-outline-secondary d-flex align-items-center me-3"
					>
						<FiArrowLeft className="me-2" />
						Cancel
					</Link>
					<Button label={"Save"} icon={"pi pi-save"} className="btn btn-primary" />
				</div>
			</div>
		</div>
	);
}
