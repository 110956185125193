import React, {useState, useEffect, useRef} from "react";
import {base, ratings_url} from "../../api";
import axios from "axios";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {Toast} from "primereact/toast";

export default function RatingsInformerPage() {
	const [data, setData] = useState({director_rating: [], employee_rating: [], self_rating: []});
	const [value1, setValue1] = useState({question_text: "", percent: ""});
	const [value2, setValue2] = useState({question_text: "", percent: ""});
	const [value3, setValue3] = useState({question_text: "", percent: ""});
	const [editingIndex, setEditingIndex] = useState({column: null, index: null});
	const toast = useRef(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get(`${base}${ratings_url}`);
				const fetchedData = response.data.reduce(
					(acc, item) => {
						if (item.question_for === 1) acc.self_rating.push(item);
						else if (item.question_for === 2) acc.employee_rating.push(item);
						else if (item.question_for === 3) acc.director_rating.push(item);
						return acc;
					},
					{director_rating: [], employee_rating: [], self_rating: []}
				);

				setData(fetchedData);
			} catch (error) {
				console.error("Error fetching data:", error);
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: "Failed to fetch data",
					life: 3000,
				});
			}
		};

		fetchData();
	}, []);

	const handleAddOrUpdate = async (column, value, setValue) => {
		let newData = {...data};

		try {
			if (editingIndex.column === column && editingIndex.index !== null) {
				const updatedItem = {
					...newData[column][editingIndex.index],
					question_text: value.question_text,
					percent: value.percent,
					question_for: column === "self_rating" ? 1 : column === "employee_rating" ? 2 : 3,
					id: newData[column][editingIndex.index].id,
				};
				await axios.put(`${base}${ratings_url}/${updatedItem.id}/`, updatedItem);
				newData[column][editingIndex.index] = updatedItem;
				setEditingIndex({column: null, index: null});
			} else {
				const response = await axios.post(`${base}${ratings_url}`, {
					question_text: value.question_text,
					percent: value.percent,
					question_for: column === "self_rating" ? 1 : column === "employee_rating" ? 2 : 3,
				});
				newData = {...data, [column]: [...(data[column] || []), response.data]};
			}
			setData(newData);
			setValue({question_text: "", percent: ""});
		} catch (error) {
			if (error.response && error.response.data) {
				if (error.response.data.question_for) {
					alert(`Error: ${error.response.data.question_for.join(", ")}`);
				} else {
					alert("An error occurred while saving the data.");
				}
			} else {
				console.error("Error saving data:", error);
				alert("An error occurred while saving the data.");
			}
		}
	};

	const handleRemove = async (column, index) => {
		try {
			const itemId = data[column][index].id;
			await axios.delete(`${base}${ratings_url}/${itemId}/`);
			const updatedColumnData = (data[column] || []).filter((_, i) => i !== index);
			const newData = {...data, [column]: updatedColumnData};
			setData(newData);
			toast.current.show({
				severity: "success",
				summary: "Deleted",
				detail: "Item deleted successfully",
				life: 3000,
			});
		} catch (error) {
			console.error("Error deleting data:", error);
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: "Failed to delete item",
				life: 3000,
			});
		}
	};

	const confirmDelete = (column, index) => {
		confirmDialog({
			message: "Do you want to delete this record?",
			header: "Delete Confirmation",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			accept: () => handleRemove(column, index),
			reject: () =>
				toast.current.show({
					severity: "info",
					summary: "Cancelled",
					detail: "Deletion cancelled",
					life: 3000,
				}),
		});
	};

	const handleEdit = (column, index, setValue) => {
		const itemToEdit = data[column][index];
		setValue({question_text: itemToEdit.question_text, percent: itemToEdit.percent});
		setEditingIndex({column, index});
	};

	const handlePercentChange = (e, setValue, value) => {
		const inputValue = e.target.value;
		const numericValue = parseInt(inputValue, 10);

		if (/^\d{0,3}$/.test(inputValue) && numericValue >= 0 && numericValue <= 100) {
			setValue({...value, percent: inputValue});
		}
	};

	const renderColumn = (column, label, value, setValue) => {
		const isFormValid = value.question_text !== "" && value.percent !== "";
		const isEditing = editingIndex.column === column && editingIndex.index !== null;
		return (
			<div className="col-12">
				<div className="w-100 fs-5 fw-bold mb-3 text-uppercase border-bottom pb-2">{label}</div>
				{(data[column] || []).map((item, index) => (
					<div key={index} className="col-12 mb-2 stretch-card">
						<div
							className={`card w-100 stretch-card ${
								isEditing && editingIndex.index === index ? "editing" : ""
							}`}
						>
							<div className="row border-bottom">
								<div className="col-10">
									<div className="d-flex justify-content-between">
										<div className="text-truncate hover-scroll overflow-hidden">
											{item.question_text}
										</div>
										<div className="ms-2 text-danger fw-bold opacity-5">{item.percent}%</div>
									</div>
								</div>
								<div className="col-2">
									<div className="row justify-content-end">
										<Button
											icon="pi pi-pencil"
											text
											className="me-1 p-0 px-1 w-auto"
											onClick={() => handleEdit(column, index, setValue)}
										/>
										<Button
											icon="pi pi-trash"
											text
											severity="danger"
											className="p-0 px-1 w-auto"
											onClick={() => confirmDelete(column, index)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
				<div className="mt-3">
					<InputText
						className="col-12 mb-3"
						value={value.question_text}
						onChange={e => setValue({...value, question_text: e.target.value})}
						placeholder="Enter text"
					/>
					<div className="p-inputgroup col-12 p-0">
						<InputText
							value={value.percent}
							onChange={e => handlePercentChange(e, setValue, value)}
							placeholder="Enter percentage"
						/>
						<span className="p-inputgroup-addon">%</span>
					</div>
					<div className="text-center mt-4 mb-3">
						<Button
							label={isEditing ? "Save" : "Add"}
							severity="primary"
							className="px-3 py-2"
							onClick={() => handleAddOrUpdate(column, value, setValue)}
							disabled={!isFormValid}
						/>
					</div>
				</div>
			</div>
		);
	};

	return (
		<div className="container-fluid">
			<Toast ref={toast} />
			<ConfirmDialog />
			<div className="col-12 p-0">
				<div className="card w-100 b-radius b-shadow p-3">
					<div className="col-12 mx-auto text-start mt-3 mb-4">
						<p className="m-0 small fst-italic text-muted">
							Արտարծույքները կարող եք ավելացնել ներքոհիշհալ դաշտում նշելով ցանկացած արտարժուք, որից
							հետո անհրաժեշտ է սեղմել "Ավելացնել" կոճակը: Իսկ եթե արտարժուքն ավելացնելուց տեղի է
							ունեցել վրիպում ապա ավելացված արտաժույքների դաշտում սեղմեք փոփոխել կոճակը և կատարեք
							փոփխությունները: Ցանկանում եք հեռացնել ոչ պիտանի արտարժուքը ապա սեղմեք ռեռացնել կոճակը
						</p>
						<hr />
					</div>
					<div className="col-12 mx-auto mb-3 p-0">
						<div className="card w-100 p-2">
							<div className="col-md-12 mx-auto mt-3">
								<div className="row">
									<div className="col-lg-4 col-md-6 col-12">
										<div className="card w-100 border-0 b-radius b-shadow m-2 p-2">
											{renderColumn("director_rating", "Ղեկավարի գնահատում", value1, setValue1)}
										</div>
									</div>
									<div className="col-lg-4 col-md-6 col-12">
										<div className="card w-100 border-0 b-radius b-shadow m-2 p-2">
											{renderColumn("employee_rating", "Աշխատակցի գնահատում", value2, setValue2)}
										</div>
									</div>
									<div className="col-lg-4 col-md-6 col-12">
										<div className="card w-100 border-0 b-radius b-shadow m-2 p-2">
											{renderColumn("self_rating", "Ինքնագնահատում", value3, setValue3)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-12 mx-auto text-start mt-3">
						<p className="m-0 small fst-italic text-muted">
							Անհրաժեշտության դեպքում, կարող եք ընտրել մի քանի արտարժույթ ավելացնելու համար:
							Արտարժույթները որ կարող եք համապատասխանել են տարբեր ծատական և այլ կիրառություններ
							համար:
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}
