import React, {useState} from "react";
import axios from "axios";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {BiRightArrowAlt} from "react-icons/bi";
import {TbCurrencyDram} from "react-icons/tb";
import {Checkbox} from "primereact/checkbox";
import ReadModeTableData from "./ReadModeTableData";

export default function EditableRow(props) {
	return (
		<div className="table-responsive p-0">
			{props.isEditing ? (
				<EditableRowInEditMode {...props} />
			) : (
				<EditableRowInReadMode {...props} />
			)}
		</div>
	);
}
function EditableRowInEditMode({
	loadContent,
	optionExpanceType,
	setDialogVisibility,
	url,
	toast,
	project,
	submitData,
	typeOptions,
	optionUnit,
	setContentLoadTrigger,
}) {
	const [value, setValue] = useState({});
	const [checked, setChecked] = useState(false);
	const headers = {"Content-Type": "application/json"};
	const credentials = "same-origin";
	let isFilled = Object.keys(value).length >= 6;
	for (const key in value) {
		if (!value[key]) {
			isFilled = false;
		}
	}
	console.log(typeOptions);

	function submitData() {
		axios({
			method: "POST",
			url: url,
			data: {
				category: value.category.id,
				count: value.count,
				identifier: value.identifier,
				project: project.id,
				salary: value.salary,
				type: value.type.id,
				// type: value.typeOptions,
				unit: value.unit.id,
			},
			headers: headers,
			credentials: credentials,
		})
			.then(res => {
				loadContent();
				toast.current.show({
					severity: "success",
					summary: "Success",
					detail: "The item is added successfully",
					life: 3000,
				});
				setDialogVisibility(false);
			})
			.catch(error => {
				toast.current.show({
					severity: "error",
					summary: "Error",
					detail: "Please add something than press the button ",
					life: 3000,
				});
			});
	}

	return (
		<div className="col-12 p-3">
			<div className="row">
				<div className="col-lg-6">
					<InputText
						onChange={e => setValue({...value, identifier: e.target.value})}
						className="py-3 w-100 m-2"
						placeholder="No:"
					/>
				</div>
				<div className="col-lg-6">
					<Dropdown
						className=" py-1 w-100 m-2"
						options={optionExpanceType}
						optionLabel={option => option.title_am.substring(0, 35)}
						value={value.type}
						placeholder={value.type ? value.type : "ընտրել հոդվածը"}
						onChange={e => {
							setValue({...value, type: e.value});
						}}
					/>
				</div>
				<div className="col-lg-6">
					<Dropdown
						className=" py-1 w-100 m-2"
						options={typeOptions}
						optionLabel={option => option.title_am.substring(0, 35)}
						value={value.category}
						placeholder={value.category ? value.category : "ընտրել ծախսերի տեսակը"}
						onChange={e => {
							setValue({...value, category: e.value});
						}}
					/>
				</div>
				<div className="col-lg-6">
					<Dropdown
						className=" py-1 w-100 m-2"
						options={optionUnit}
						optionLabel={option => option.title_am.substring(0, 35)}
						value={value.unit}
						placeholder={value.unit ? value.unit : "ընտրել չափման միավորը"}
						onChange={e => {
							setValue({...value, unit: e.value});
						}}
					/>
				</div>
				<div className="col-lg-6">
					<InputText
						onChange={e => setValue({...value, count: e.target.value})}
						className="py-3 w-100 m-2"
						placeholder="Քանակ"
					/>
				</div>
				<div className="col-lg-6">
					<InputText
						onChange={e => setValue({...value, salary: e.target.value})}
						className="py-3 w-100 m-2"
						placeholder="Գին $"
					/>
				</div>
				{value?.count && (
					<div className="col-lg-12">
						<div className="flex align-items-center justify-content-center mt-4">
							<Checkbox
								inputId="checkbox"
								onChange={e => setChecked(e.checked)}
								checked={checked}
							/>
							<label htmlFor="checkbox" className="ml-2 text-uppercase border-bottom">
								Ստեղծե՞լ բոլոր{" "}
								<span className="text-danger fw-bolder opacity-75">{value?.count}</span> տողերը
							</label>
						</div>
					</div>
				)}
				<div className="col-lg-12 text-center mt-4">
					<Button
						icon={"pi pi-save"}
						rounded
						label="պահպանել"
						severity={isFilled ? "success" : "secondary"}
						onClick={submitData}
						disabled={!isFilled}
						setContentLoadTrigger={setContentLoadTrigger(true)}
					/>
				</div>
			</div>
		</div>
	);
}
function EditableRowInReadMode(props) {
	return <ReadModeTableData data={props} />;
}
