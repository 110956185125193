import React, {useEffect, useState} from "react";
import {base, expances_row_by_project} from "../../../api";
import {Accordion, AccordionTab} from "primereact/accordion";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button"; // Importing PrimeReact Button
import axios from "axios";

export default function ExpancesByRow(props) {
	const [data, setData] = useState([]);
	const [newRows, setNewRows] = useState({}); // Store new rows per accordion tab
	const [error, setError] = useState(null);

	useEffect(() => {
		fetchData();
	}, []);

	const fetchData = async () => {
		try {
			const response = await axios.get(`${base}${expances_row_by_project}${props.data}/`);
			setData(response.data);
		} catch (err) {
			setError("Error fetching data");
		}
	};

	const handleAddRow = itemIndex => {
		setNewRows(prev => ({
			...prev,
			[itemIndex]: [
				...(prev[itemIndex] || []),
				{amount: "", date: "", partner: "", file: ""}, // Add new empty row
			],
		}));
	};

	const handleInputChange = (itemIndex, rowIndex, field, value) => {
		const updatedRows = [...(newRows[itemIndex] || [])];
		updatedRows[rowIndex] = {...updatedRows[rowIndex], [field]: value};
		setNewRows(prev => ({...prev, [itemIndex]: updatedRows}));
	};

	const handleSaveRows = async itemIndex => {
		try {
			const rowsToSave = newRows[itemIndex];
			await axios.post(`${base}/save/expances/`, rowsToSave); // Update the endpoint accordingly
			setNewRows(prev => ({...prev, [itemIndex]: []})); // Clear after saving
			fetchData(); // Refresh data
		} catch (err) {
			setError("Error saving data");
		}
	};

	const HeaderContent = data => {
		return (
			<div className="row mx-3 fw-bold tabel-div d-flex align-items-center p-0">
				<InputText
					className="col-1 me-2 border-0 bg-transparent text-dark p-0"
					disabled
					value={data?.data?.category?.code}
				/>
				<InputText
					className="col-4 me-2 border-0 bg-transparent text-dark p-0"
					disabled
					value={data?.data?.category?.title_am}
				/>
				<InputText
					className="col-1 me-2 border-0 bg-transparent text-dark p-0"
					disabled
					value={data?.data?.count}
				/>
				<InputText
					className="col me-2 border-0 bg-transparent text-dark p-0"
					disabled
					value="Մատակարար"
				/>
				<InputText
					className="col me-2 border-0 bg-transparent text-dark p-0"
					disabled
					value="Ֆայլ"
				/>
			</div>
		);
	};

	return (
		<div className="card">
			{error && <div className="alert alert-danger">{error}</div>}
			<Accordion multiple activeIndex={[0]}>
				{data?.map((item, itemIndex) => (
					<AccordionTab key={itemIndex} header={<HeaderContent data={item} />} className="mb-2">
						{/* Existing rows */}
						{item?.expance_rows?.map((row, rowIndex) => (
							<div className="tabel-div d-flex align-items-center p-0 my-3" key={rowIndex}>
								<InputText className="col me-2" value={row?.amount} />
								<InputText className="col me-2" value={row?.date} />
								<InputText className="col me-2" value={row?.partner?.full_name_am} />
								{row?.file ? (
									<InputText className="col me-2" value={row?.file} />
								) : (
									<InputText className="col me-2" value="file" />
								)}
							</div>
						))}

						{/* Dynamically added rows */}
						{newRows[itemIndex]?.map((row, rowIndex) => (
							<div className="tabel-div d-flex align-items-center p-0 my-3" key={`new-${rowIndex}`}>
								<InputText
									className="col me-2"
									value={row.amount}
									onChange={e => handleInputChange(itemIndex, rowIndex, "amount", e.target.value)}
									placeholder="Amount"
								/>
								<InputText
									className="col me-2"
									value={row.date}
									onChange={e => handleInputChange(itemIndex, rowIndex, "date", e.target.value)}
									placeholder="Date"
								/>
								<InputText
									className="col me-2"
									value={row.partner}
									onChange={e => handleInputChange(itemIndex, rowIndex, "partner", e.target.value)}
									placeholder="Partner"
								/>
								<InputText
									className="col me-2"
									value={row.file}
									onChange={e => handleInputChange(itemIndex, rowIndex, "file", e.target.value)}
									placeholder="File"
								/>
							</div>
						))}

						{/* Button to add a new row */}
						<Button
							label="Add New Row"
							icon="pi pi-plus"
							className="mt-3"
							onClick={() => handleAddRow(itemIndex)}
						/>

						{/* Button to save the new rows */}
						{newRows[itemIndex]?.length > 0 && (
							<Button
								label="Save Rows"
								icon="pi pi-save"
								className="mt-3 ml-2"
								onClick={() => handleSaveRows(itemIndex)}
							/>
						)}
					</AccordionTab>
				))}
			</Accordion>
		</div>
	);
}
